'use client';

import React, { useMemo } from 'react';
import {
// useQuery,
// useQueryClient,
QueryClient, QueryClientProvider } from '@tanstack/react-query';

// import { GlobalStateProvider } from '#/providers/GlobalStateContext';
import UserContextProvider from '#/providers/UserContext';
import WagmiProvider from '#/providers/W3Provider';
const RootProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  // Create a client
  const queryClient = useMemo(() => new QueryClient(), []);
  return <div data-sentry-component="RootProvider" data-sentry-source-file="providers.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="providers.tsx">
        <UserContextProvider data-sentry-element="UserContextProvider" data-sentry-source-file="providers.tsx">
          <WagmiProvider data-sentry-element="WagmiProvider" data-sentry-source-file="providers.tsx">{children}</WagmiProvider>
        </UserContextProvider>
      </QueryClientProvider>
    </div>;
};

// Exports
// ========================================================
export default RootProvider;