'use client';

import React, { useMemo } from 'react';
import { RainbowKitAuthenticationProvider, RainbowKitProvider, darkTheme, DisclaimerComponent } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';
import CustomAvatar from './customAvatar';
import useAuth from './useAuth';
import WagmiConfigs from './wagmiConfigs';
const WagmiProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const {
    authStatus,
    authAdapter
  } = useAuth();
  const client = useMemo(() => WagmiConfigs.client, []);
  const chains = useMemo(() => WagmiConfigs.chains, []);
  const theme = useMemo(() => darkTheme({
    accentColor: '#26B400',
    accentColorForeground: 'white',
    borderRadius: 'small',
    fontStack: 'system',
    overlayBlur: 'large'
  }), []);
  const Disclaimer: DisclaimerComponent = useMemo(() => ({
    Text,
    Link
  }) => <Text>
          By connecting your wallet, you agree to the{' '}
          <Link href="https://SimpFi.ai/terms">Terms of Gangster</Link> and
          acknowledge you have read and understand the protocol{' '}
          <Link href="https://SimpFi.ai/legal">Disclaimer</Link>
        </Text>, []);
  return <WagmiConfig client={client} data-sentry-element="WagmiConfig" data-sentry-component="WagmiProvider" data-sentry-source-file="index.tsx">
      <RainbowKitAuthenticationProvider adapter={authAdapter} status={authStatus} data-sentry-element="RainbowKitAuthenticationProvider" data-sentry-source-file="index.tsx">
        <RainbowKitProvider coolMode chains={chains} modalSize="compact" theme={theme} appInfo={{
        appName: 'SimpFi ALPHA',
        disclaimer: Disclaimer,
        learnMoreUrl: 'https://SimpFi.Ai'
      }} avatar={CustomAvatar} data-sentry-element="RainbowKitProvider" data-sentry-source-file="index.tsx">
          {children}
        </RainbowKitProvider>
      </RainbowKitAuthenticationProvider>
    </WagmiConfig>;
};
export default WagmiProvider;